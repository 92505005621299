import React from "react";
import { Link } from "react-router-dom";
import "./newnav.css";

const Newnav = () => {
  return (
    <nav className="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-brand">
          alydvisuals
        </Link>
      </div>
      <div className="navbar-right">
        <Link to="/portfolio">Portfolio</Link>
        <Link to="/pricing">Pricing</Link>
        <Link to="/contact-us">Contact Us</Link>
      </div>
    </nav>
  );
};

export { Newnav };
