import React from "react";
import { Routes, Route } from "react-router-dom";
import App from "./App";
import PortfolioPage from "./Portfolio";
import { PricingPage } from "./Pricing";
import { ContactUs } from "./Contact";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/portfolio" element={<PortfolioPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/contact-us" element={<ContactUs />} />
    </Routes>
  );
};

export default Router;
