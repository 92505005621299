import galleryImages from "./images/gallery";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const generateSrcSet = (photo) => {
  return breakpoints.map((breakpoint) => {
    const height = Math.round((photo.height / photo.width) * breakpoint);
    return {
      src: photo.src,
      width: breakpoint,
      height,
    };
  });
};

const getImageDimensions = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.src = src;
  });
};

export const images = async () => {
  // Convert the gallery images object into an array of photos
  const photoArr = await Promise.all(
    Object.entries(galleryImages).map(async ([key, value]) => {
      const dimensions = await getImageDimensions(value);
      return {
        src: value,
        order: parseInt(key),
        width: dimensions.width,
        height: dimensions.height,
      };
    })
  );

  // Sort photos by order
  photoArr.sort((a, b) => a.order - b.order);

  // Format photos for react-photo-album
  return photoArr.map((photo) => ({
    src: photo.src,
    width: photo.width,
    height: photo.height,
    alt: `Photo ${photo.order}`,
    srcSet: generateSrcSet(photo),
  }));
};
