// Function to dynamically import all images from the gallery directory
const importAll = (r) => {
  const images = {};
  r.keys().forEach((key) => {
    const imageNumber = key.match(/\/(\d+)\./)[1];
    images[imageNumber] = r(key);
  });
  return images;
};

// Dynamically import all images from the gallery directory
const galleryImages = importAll(
  require.context("./", false, /\.(png|jpe?g|gif|webp)$/)
);

export default galleryImages;
